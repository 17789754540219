import { IStudy } from 'common';
import { IModelCardResponsePayload } from './payload';

export interface ITransaction {
    response: IPredictionResponse;
    time_taken: number;
    deployment_id: string;
    transaction_id: string;
    image_id: string;
    model_id: string;
    dataset_id: string;
    time_stamp: string;
    user_id: string;

    batch_job_id?: string;
    input: Dictionary<any>;
    model_parameters: Dictionary<any>;
    hyperparameters: Dictionary<any>;
    start: number;
    end: number;
    output: Dictionary<any>;
    status: TransactionStatus;

    model?: IModelCardResponsePayload; // Virtual
    study?: Pick<IStudy, 'study_id' | 'alias' | 'description' | 'series_list'>; // Virtual
}

export interface IPredictionResponse {
    prediction_class: number;
    confidence: number;
    logits: number[];
    status: number;
}

export interface GetTransactionListRequestPayload {
    batch_job_id?: string;
}

export enum TransactionStatus {
    QUEUED = 'QUEUED',
    INPROGRESS = 'INPROGRESS',
    RETRYING = 'RETRYING',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}
