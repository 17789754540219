import { _get } from './_get';

export function _mean(collection: Array<number>, fractionDigits?: number): number {
    if (!collection || collection.length === 0) {
        return NaN;
    }

    let sum = 0;
    const length = collection.length;

    // Calculate sum using the iteratee function
    for (const value of collection) {
        sum += value;
    }

    const result = sum / length;
    if (fractionDigits !== undefined && !isNaN(result)) {
        return Number(result.toFixed(fractionDigits));
    }
    return result;
}

export function _meanBy<T>(
    collection: Array<T> | null | undefined,
    iteratee: ((value: T) => number) | string,
    fractionDigits?: number
): number {
    if (!collection || collection.length === 0) {
        return NaN;
    }

    if (typeof iteratee === 'string') {
        const key = iteratee;
        iteratee = (obj: T) => Number(_get(obj, key) as unknown as number) || 0;
    }

    return _mean(collection.map(iteratee), fractionDigits);
}
