export type IReportedIssueType = 'bug-report' | 'doc-improvement' | 'feature-request' | 'sec-vulnerability';
export type IReportStatus = 'inprogress' | 'resolved' | 'blocked' | 'rejected';

export const statusEnum = {
    inprogress: 'inprogress' as IReportStatus,
    resolved: 'resolved' as IReportStatus,
    blocked: 'blocked' as IReportStatus,
    rejected: 'rejected' as IReportStatus,
};
interface ICommonFields {
    id?: string;
    issue_id: string;
    user_id?: string;
    reoprter?: string;
    type: IReportedIssueType;
    title: string;
    description?: string;
    status: IReportStatus;
    created_timestamp: number;
    feedback?: string;
}

export type Issue = IBugReport | IDocumentImprovement | IFeatureRequest | ISecurityVulnerability;
export interface IBugReport extends ICommonFields {
    current_behavior: string;
    expected_behavior: string;
}

export interface IDocumentImprovement extends ICommonFields {
    url: string;
}

export interface IFeatureRequest extends ICommonFields {
    preferred_solution: string;
    additional_context?: string;
}

export interface ISecurityVulnerability extends ICommonFields {}

export default IReportedIssueType;

export type IReportedIssues = IBugReport | IDocumentImprovement | IFeatureRequest | ISecurityVulnerability;
export interface IReportedIssueResponse {
    issues: IReportedIssues[];
}

export const statusTextMap: Record<IReportStatus, string> = {
    inprogress: 'In Progress',
    resolved: 'Resolved',
    blocked: 'Blocked',
    rejected: 'Rejected',
};

export const typeTextMap: Record<IReportedIssueType, string> = {
    'bug-report': 'Bug Report',
    'doc-improvement': 'Documentation Improvement',
    'feature-request': 'Feature Request',
    'sec-vulnerability': 'Security Vulnerability',
};
