

export interface IUsageStatistics {
    loading: boolean;
}

export function UsageStatisticsFactory(data?: Partial<IUsageStatistics>): IUsageStatistics {
    return {
        loading: data?.loading || false,
    };
}



