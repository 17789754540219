import React from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import Styles from './Styles.module.scss';

export default function GRichTextEditor({ value, onChange, placeholder = 'Write something...', ...props }: IProps) {
    const quillRef = React.useRef<any>(null);

    const handleChange = (content: string, delta: any, source: string, editor: any) => {
        const fullDelta = editor.getContents(); // Full Delta of the document
        onChange?.(fullDelta); // Pass the Delta to the parent
    };

    return (
        <ReactQuill
            readOnly={false}
            className={Styles.GRichTextEditor}
            modules={{
                toolbar: !props.readOnly ? defaultToolbarOptions : false,
            }}
            ref={quillRef}
            onChange={handleChange}
            theme="snow"
            {...props}
        />
    );
}
export const defaultToolbarOptions = [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'], // Formatting buttons
    [{ list: 'ordered' }, { list: 'bullet' }], // List buttons
    ['link', 'image'], // Link and image
    ['clean'], // Clear formatting
];

interface IProps extends ReactQuillProps {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    toolbarOptions?: any;
}
