export enum DeploymentStatus {
    UP = 'UP',
    DOWN = 'DOWN',
    INPROGRESS = 'INPROGRESS',
    STOP = 'STOP',
    FAILED = 'FAILED',
    STOPPING = 'STOPPING',
}

export enum ProblemType {
    classification = 'Classification',
    segmentation = 'Segmentation',
    semantic_segmentation = 'Semantic Segmentation',
    instance_segmentation = 'Instance Segmentation',
    object_detection = 'Object Detection',
    // regression = "Regression",
}
export enum ModelMode {
    VendorServing = 'vendor_serving',
    LocalModel = 'local_model',
    ExternalModel = 'external_model',
    SmartAnnotation = 'smart_annotation',
    MonaiModel = 'monai',
    Experimental = 'experimental',
    TrainedModel = 'trained_model',
}

export const ModelModeMap = {
    [ModelMode.VendorServing]: 'Vendor Serving',
    [ModelMode.LocalModel]: 'Local Model',
    [ModelMode.ExternalModel]: 'External Model',
    [ModelMode.SmartAnnotation]: 'Smart Annotation',
    [ModelMode.MonaiModel]: 'Monai Model',
    [ModelMode.Experimental]: 'Experimental Model',
    [ModelMode.TrainedModel]: 'Trained Model',
};

export enum ModelType {
    vendor_serving = 'Vendor Serving',
    local_model = 'Local Model',
    external_model = 'External Model',
    smart_annotation = 'Smart Annotation',
}

export type ProblemTypeKeys = keyof typeof ProblemType;

export type ShapeBasedProblemTypeKeys = Exclude<ProblemTypeKeys, 'classification'>;

export const isSegmentation = (problemType: ProblemTypeKeys) =>
    problemType === 'segmentation' || problemType === 'instance_segmentation';
