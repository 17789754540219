import { IStudy } from 'common';

export interface AnnotationAgreementState {
    metricLevel: MetricLevelType;
}

export function AnnotationAgreementStateFactory(data?: Partial<AnnotationAgreementState>): AnnotationAgreementState {
    return {
        metricLevel: 'area',
        ...data,
    };
}

export interface IAnnotationMetricsResponse<T = AnnotationMetricsAvgData[]> {
    data: T;
    comparison: Array<[string, string]>;
    studyIdList: string[];
}

export interface AnnotationMetricsAvgData {
    label: string;
    study_id?: string;
    image_id?: string;
    image_index?: number;
    series_id?: string;
    comparison: Dictionary<IComparison>;
    mean_area?: number;
    mean_volume?: number;
}

export interface AnnotationMetricsAvgRequestPayload {
    annotation_id: string;
    avg_type: AvgType;
    comparison_type: ComparisonType;
    study_id?: string;
    study_list?: string;
    user_id?: string;
    level?: MetricLevelType;
}

export interface BlandAltmanRequestPayload {
    annotation_id: string;
    study_id?: string;
    level: MetricLevelType;
    match: string;
    label?: string;
}

export interface BlandAltmanResponse {
    data: BlandAltmanData;
    studyIdList: string[];
    comparison: Array<[string, string]>;
}

export interface BlandAltmanData {
    mean_difference: number;
    std_difference: number;
    lower_limit: number;
    upper_limit: number;
    plots: Array<BlandAltmanPlotItem>;
}

export interface BlandAltmanPlotItem {
    instance_index: number;
    mean_area: number;
    normalized_difference: number;
    x: number;
    y: number;
    id: string;
    study_id: string;
    series_id: string;
}

export interface IccMetricsRequestPayload {
    annotation_id: string;
    study_id?: string;
    level?: MetricLevelType;
    match?: string;
    study_list?: string;
}

export interface IccMetricsItem {
    label: string;
    ICC1_1: number;
    ICC2_1: number;
    ICC3_1: number;
    ICC1_k: number;
    ICC2_k: number;
    ICC3_k: number;
}

export type IccMetricKey = keyof Omit<IccMetricsItem, 'label'>;

export interface VolumeListRequestPayload {
    annotation_id: string;
    comparison_type: ComparisonType;
}

export interface VolumeListResponse {
    data: VolumeListData[];
    studyIdList: string[];
    userList: { email: string; displayName: string; _id: string }[];
    projectList: { annotation_id: string; annotation_name: string }[];
}

export interface AreaListRequestPayload {
    annotation_id: string;
    comparison_type: ComparisonType;
}

export interface AreaListResponse {
    data: AreaListData[];
    studyIdList: string[];
    userList: { email: string; displayName: string; _id: string }[];
    projectList: { annotation_id: string; annotation_name: string }[];
}

export interface VolumeListData {
    label: string;
    study_id: string;
    series_id: string;
    volume_value: Dictionary<number>;
}

export interface AreaListData {
    label: string;
    study_id: string;
    series_id: string;
    image_id: string;
    image_index: number;
    area: Dictionary<number>;
    pixel: Dictionary<number>;
}

export interface IComparison {
    match: string;
    dice: number;
    hausdorff: { max: number; avg: number };
    mean_area?: number;
}

export type ComparisonType = 'project' | 'user';
export type AvgType = 'study' | 'label' | 'all';

export type MetricLevelType = 'volume' | 'area';

export interface MetricsOptions {
    studyList: Array<Pick<IStudy, 'study_id' | 'alias' | 'description'>>;
    comparison: Array<[string, string]>;
    labelList: Array<string>;
}
