import { io, Socket } from 'socket.io-client';

export class SocketService {
    socket: Socket;

    constructor() {
        this.initSocket = this.initSocket.bind(this);
    }

    initSocket() {
        this.socket = io('/', { path: '/events', auth: { token: sessionStorage.getItem('accessToken') } });

        this.socket.on('connect', () => {
            console.log('connected to socket events');
        });
    }

    subscribeEvent(event: string, id: string, data: any, cb?: (...args: any[]) => void) {
        this.socket.emit('subscribe', event, id, data);

        if (cb) this.socket.on(event, cb);
    }

    unSubscribeEvent(event: string, id: string) {
        this.socket.emit('unsubscribe', event, id);
    }

    on(event: string, cb: (...args: any[]) => void) {
        this.socket.on(event, cb);
    }
}

export const socketService = new SocketService();
