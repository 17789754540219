import { ScatterPlot, ScatterPlotMouseHandler, ScatterPlotNodeDynamicSizeSpec, ScatterPlotSvgProps } from '@nivo/scatterplot';
import { ChartHelper, Dictionary } from 'common';
import { useRef } from 'react';
import useEventCallback from 'use-event-callback';
import { IBaseChartProps } from '../entities';
import { ISerie, useSerieLegend } from '../hooks/useSerieLegend';
import { nivoTheme, nivoThemeLight } from '../nivoTheme';

export default function ScattorPlotChart({ chartID, chartOptions, data, tooltip, className, ...props }: IScatterPlotChartProps) {
    const lightTheme = chartOptions?.lightTheme;
    const { selectedSeries } = useSerieLegend({ data, lightTheme });
    const chartData = data?.filter(item => selectedSeries?.includes(item.id));

    const sizeMap = useRef<Dictionary<number>>({});

    const onMouseEnter: ScatterPlotMouseHandler<any> = useEventCallback((node, e) => {
        if (chartOptions?.highlightOnHover) {
            sizeMap.current[node.id] = node.size;
            node.size = node.size * 1.5;
        }
        (e.target as any).style.cursor = 'pointer';
    });

    const onMouseLeave: ScatterPlotMouseHandler<any> = useEventCallback((node, e) => {
        if (chartOptions?.highlightOnHover && sizeMap.current[node.id]) {
            node.size = sizeMap.current[node.id];
        }
        (e.target as any).style.cursor = 'default';
    });

    return (
        <>
            <div
                id={chartID}
                className={className}
            >
                <ScatterPlot
                    width={props.width}
                    height={props.height}
                    data={chartData}
                    yScale={chartOptions?.yScale}
                    xScale={chartOptions?.xScale}
                    xFormat=" >-.1f"
                    yFormat=">-.1f"
                    nodeSize={chartOptions?.nodeSize ?? 10}
                    axisBottom={chartOptions?.axisBottom}
                    axisLeft={chartOptions?.axisLeft}
                    theme={chartOptions?.lightTheme ? nivoThemeLight : nivoTheme}
                    enableGridX={chartOptions?.enableGridX}
                    enableGridY={chartOptions?.enableGridY}
                    layers={chartOptions?.layers}
                    markers={chartOptions?.markers}
                    colors={chartOptions.colors ?? ['#0095ff']}
                    tooltip={tooltip}
                    onClick={chartOptions?.onPointClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    legends={chartOptions?.legends ? chartOptions?.legends : []}
                    margin={chartOptions?.margin ?? { top: 10, right: 10, bottom: 40, left: 40 }}
                />
            </div>
        </>
    );
}

interface IScatterPlotChartProps extends IBaseChartProps {
    data?: Array<ISerie>;
    getChartHelperRef?: (chartHelperRef: ChartHelper.XYChart) => void;
    chartOptions?: ScartterChartOptions;
    tooltip?: any;
    nodeComponent?: React.ReactNode;
}

export interface ScartterChartOptions extends Partial<Omit<ScatterPlotSvgProps<any>, 'width' | 'height'>> {
    onPointClick?: (point: Dictionary<any>, ev: any) => void;
    legendEnabled?: boolean;
    zoomEnabled?: boolean;
    nodeSize?: number | ScatterPlotNodeDynamicSizeSpec;
    highlightOnHover?: boolean;
    lightTheme?: boolean;
    markers?: any[];
    layers?: any[];
    dimensions?: any[];
    colors?: string[] | { scheme: string } | any;
    axisBottom?: any;
    axisLeft?: any;
    axisRight?: any;
    axisTop?: any;
    legends?: any;
}
